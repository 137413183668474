<template>
	<a-form layout="vertical" :form="form" @submit="handleSubmit">
		<div class="row">
			<div class="col-md-8">
				<div class="row">
					<div class="col-md-4">
						<a-form-item :label="$t('suppliersModule.tableHeaders.nacionality')">
							<a-select style="width: 100%" v-decorator="['nationality', { rules: requiredFileds }]">
								<a-select-option value="national">{{ $t('suppliersModule.edit.generalInfo.national') }}</a-select-option>
								<a-select-option value="foreign">{{ $t('suppliersModule.edit.generalInfo.foreign') }}</a-select-option>
							</a-select>
						</a-form-item>
					</div>
					<div class="col-md-4">
						<a-form-item :label="$t('suppliersModule.tableHeaders.email')">
							<a-input v-decorator="['email', { rules: emailField }]" autocomplete="off" />
						</a-form-item>
					</div>
					<div class="col-md-4">
						<a-form-item :label="$t('suppliersModule.tableHeaders.phones')">
							<a-input v-decorator="['phone', { rules: requiredFileds }]" autocomplete="off" />
						</a-form-item>
					</div>
					<div class="col-md-12">
						<a-form-item :label="$t('suppliersModule.tableHeaders.name')">
							<a-input v-decorator="['full_name', { rules: requiredFileds }]" autocomplete="off" class="text-uppercase" />
						</a-form-item>
					</div>
					<div class="col-md-12">
						<a-form-item :label="$t('suppliersModule.edit.generalInfo.comments')">
							<a-textarea v-decorator="['comments']" />
						</a-form-item>
					</div>
				</div>
			</div>
			<div class="col-md-4 text-center">
				<b>Servicios que ofrece el proveedor</b>
				<div class="text-left pt20">
					<div v-for="service, index in services" :key="index">
						<a-checkbox :value="service.id" :checked="isChecked(service.id)" @change="onChange">{{ service.description }}</a-checkbox>
					</div>
					<a-checkbox value="-1" :checked="isChecked('-1')" @change="onChange">Otros servicios</a-checkbox>
				</div>
				<a-textarea v-model="otherServices" v-if="hasOtherServices"></a-textarea>
			</div>
		</div>
		<div class="row pt20" v-show="!isEmbedded">
			<div class="col-md-4 text-left">
				<a-button class="btn btn-warning" icon="arrow-left" @click="onCancel">{{ $t('general.back') }}</a-button>
			</div>
			<div class="col-md-8 text-right">
				<a-button icon="save" class="btn btn-success" htmlType="submit" id="onSaveSupplierBtn">{{ $t('general.save') }}</a-button>
			</div>
		</div>
	</a-form>
</template>
<script>
//
import { mapGetters } from 'vuex'
import utilities from '@/services/utilities'
import { services } from '@/constants'
function onlyUnique(value, index, array) {
	return array.indexOf(value) === index;
}

export default {
	name: 'supplierGeneralComponent',
	props: {
		isEmbedded: {
			type: Boolean,
			default: false,
		},
		supplierID: {
			type: String,
			default: '',
		},
		projectID: {
			default: '',
		},
	},
	computed: {
		...mapGetters('suppliers', ['actualRecord']),
		...mapGetters('auth', ['userData']),
		isNewRecord() {
			if (this.isEmbedded) {
				return true
			} else {
				return this.$route.params.id == 'new' ? true : false
			}
		},
		requiredFileds() {
			let rules = [
				{
					required: true,
					message: this.$t('general.requiredField'),
				},
			]
			return rules
		},
		emailField() {
			let rules = [
				{
					required: true,
					message: this.$t('general.requiredField'),
				},
				{
					email: true,
					message: this.$t('general.validEmail'),
				},
			]
			return rules
		},
		hasOtherServices() {
			return this.selectedServices ? this.selectedServices.findIndex(e => e == '-1') >= 0 : false
		},
	},
	data() {
		return {
			form: this.$form.createForm(this),
			services,
			selectedServices: [],
			otherServices: '',
		}
	},
	mounted() {
		if (utilities.objectValidate(this.actualRecord, 'general.id', false)) {
			this.setLocalData(this.actualRecord)
		}
	},
	methods: {
		cleanData() {
			this.form.resetFields()
			this.selectedServices = []
			this.otherServices = ''
		},
		onCancel() {
			if (this.userData.role == 'supplier') {
				this.$router.replace('/')
			} else {
				this.$router.replace('/suppliers')
			}
		},
		async setLocalData(newValue) {
			if (newValue) {
				// console.log('newValue-->', newValue);
				this.form.setFieldsValue({
					nationality: utilities.objectValidate(newValue, 'general.nationality', ''),
					full_name: utilities.objectValidate(newValue, 'general.full_name', ''),
					email: utilities.objectValidate(newValue, 'general.email', ''),
					phone: utilities.objectValidate(newValue, 'general.phone', ''),
					comments: utilities.objectValidate(newValue, 'general.comments', ''),
				})

				if (utilities.objectValidate(newValue, 'general.selected_services', false)) {
					this.selectedServices = _.cloneDeep(JSON.parse(newValue.general.selected_services))
				}

				this.otherServices = utilities.objectValidate(newValue, 'general.other_services', '')
			}
		},
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields(async (err, values) => {
				if (!err) {
					//
					let payload = {
						...values,
						section: 'suppliers',
						selected_services: JSON.stringify(this.selectedServices),
						other_services: this.otherServices,
					}
					if (this.isEmbedded) {
						payload = {
							...payload,
							isEmbedded: true,
						}
					}
					if (this.isNewRecord) {
						this.$store.dispatch('suppliers/CREATE', payload).then(response => {
							if (this.isEmbedded) {
								this.$store
									.dispatch('suppliers/CREATE_RELATIONSHIP', {
										supplier_ids: [response.data.supplier_id],
										project_id: this.projectID,
									})
									.then(() => {
										this.$emit('close')
									})
							}
						}).then(() => {
							if (this.userData.role != 'supplier') {
								this.$router.replace('/suppliers')
							}
						})
					} else {
						payload.supplier_id = this.actualRecord.general.id
						this.$store.dispatch('suppliers/UPDATE', payload).then(() => {
							if (this.userData.role != 'supplier') {
								this.$router.replace('/suppliers')
							}
						})
					}
				}
			})
		},
		onChange(e) {
			let { value, checked } = e.target

			this.selectedServices.push(value)
			this.selectedServices = this.selectedServices.filter(onlyUnique)

			if (!checked) {
				let index = this.selectedServices.findIndex(e => e == value)
				this.selectedServices.splice(index, 1)
			}
		},
		isChecked(id) {
			if (this.selectedServices && !!this.selectedServices.find(e => e == id)) {
				return true
			}
			return false
		},
	},
	watch: {
		actualRecord: {
			deep: true,
			handler(newValue) {
				if (utilities.objectValidate(newValue, 'general.id', false)) {
					setTimeout(() => {
						this.setLocalData(newValue)
					}, 100)
				}
			},
		},
	},
}
</script>
